<template>
  <svg width="211"
       height="52"
       viewBox="0 0 211 52"
       xmlns="http://www.w3.org/2000/svg"
       :style="{backgroundColor: backgroundFill}">
    <path d="M84.746 30.027C84.746 30.2248 84.68 30.4226 84.548 30.5544C84.416 30.6863 84.218 30.8181 84.02 30.8181H78.872C78.41 30.8181 78.146 30.5544 78.146 30.027V2.93172C78.146 2.47024 78.41 2.20654 78.872 2.20654H84.02C84.482 2.20654 84.746 2.47024 84.746 2.93172V30.027Z"
          :fill="foregroundFill"/>
    <path d="M116.162 30.2907C116.162 30.6204 115.964 30.8181 115.502 30.8181H110.42C110.222 30.8181 110.09 30.7522 109.958 30.5544C109.826 30.4226 109.76 30.2248 109.76 30.027V14.0072C109.76 13.8094 109.694 13.6776 109.562 13.6776C109.43 13.6776 109.364 13.7435 109.232 13.9413L105.734 20.2701C105.47 20.7316 105.074 20.9293 104.546 20.9293H101.444C100.916 20.9293 100.52 20.7316 100.256 20.2701L96.758 13.9413C96.626 13.7435 96.56 13.6776 96.428 13.6776C96.296 13.6776 96.23 13.8094 96.23 14.0072V30.027C96.23 30.2248 96.164 30.4226 96.032 30.5544C95.9 30.6863 95.768 30.8181 95.57 30.8181H90.488C90.026 30.8181 89.828 30.6204 89.828 30.2907V3.52505C89.828 3.1295 89.96 2.79987 90.224 2.53617C90.488 2.27247 90.818 2.20654 91.214 2.20654H94.844C95.636 2.20654 96.164 2.53617 96.56 3.26135L102.434 14.205C102.632 14.5346 102.764 14.7324 102.83 14.7324C102.962 14.7324 103.094 14.5346 103.226 14.205L109.1 3.26135C109.43 2.53617 110.024 2.20654 110.816 2.20654H114.446C114.842 2.20654 115.172 2.33839 115.436 2.6021C115.7 2.8658 115.832 3.19542 115.832 3.59098V30.2907H116.162Z"
          :fill="foregroundFill"/>
    <path d="M142.694 11.502C142.694 13.2161 142.364 14.7323 141.704 15.9849C141.044 17.2375 140.186 18.2264 139.064 18.8856C137.942 19.5449 136.754 20.0723 135.434 20.336C134.18 20.6656 132.728 20.7975 131.21 20.7975C131.078 20.7975 130.022 20.7315 128.108 20.6656V30.027C128.108 30.2248 128.042 30.4226 127.91 30.5544C127.778 30.6863 127.58 30.8181 127.382 30.8181H122.432C122.234 30.8181 122.036 30.7522 121.904 30.6203C121.772 30.4885 121.64 30.2907 121.64 30.0929V4.2502C121.64 3.65688 121.772 3.1954 122.036 2.9317C122.3 2.668 122.762 2.47022 123.356 2.40429C125.468 2.07467 128.108 1.87689 131.21 1.87689C132.794 1.87689 134.18 2.00874 135.5 2.33837C136.82 2.60207 138.008 3.12947 139.13 3.78873C140.252 4.44798 141.176 5.43686 141.77 6.68944C142.43 7.94202 142.76 9.45831 142.76 11.1724V11.502H142.694ZM136.16 11.502V11.1724C136.16 9.85386 135.764 8.86498 134.972 8.27165C134.18 7.67832 132.926 7.3487 131.276 7.3487C130.088 7.3487 129.098 7.41462 128.174 7.48055V15.3257C128.768 15.3916 129.758 15.3916 131.276 15.3916C132.992 15.3916 134.246 15.062 135.038 14.4027C135.764 13.8094 136.16 12.8205 136.16 11.502Z"
          :fill="foregroundFill"/>
    <path d="M166.916 30.2248C166.916 30.6203 166.718 30.8181 166.256 30.8181H160.844C160.448 30.8181 160.184 30.5544 160.052 30.0929L158.666 23.7641H150.746L149.36 30.0929C149.228 30.5544 149.03 30.8181 148.568 30.8181H143.156C142.694 30.8181 142.496 30.6203 142.496 30.2248L142.562 30.1589C142.562 30.0929 142.562 30.0929 142.562 30.027L148.304 6.16203C148.7 4.5139 149.492 3.39317 150.614 2.79984C151.736 2.20651 153.122 1.94281 154.706 1.94281C156.356 1.94281 157.676 2.20651 158.798 2.79984C159.92 3.39317 160.646 4.5139 161.042 6.16203L166.784 30.027C166.784 30.027 166.784 30.0929 166.85 30.1589V30.2248H166.916ZM157.676 18.556L155.3 7.94202C155.234 7.54646 155.036 7.34869 154.772 7.34869C154.508 7.34869 154.31 7.54646 154.244 7.94202L151.868 18.556H157.676Z"
          :fill="foregroundFill"/>
    <path d="M186.914 29.6315C186.914 30.0929 186.65 30.3566 186.056 30.4885C184.736 30.95 182.822 31.1477 180.314 31.1477C178.796 31.1477 177.344 30.95 176.024 30.4885C174.704 30.027 173.516 29.3678 172.394 28.4448C171.272 27.5219 170.414 26.2693 169.82 24.6211C169.16 22.973 168.896 21.0612 168.896 18.8856V14.0072C168.896 11.8316 169.226 9.85386 169.82 8.27166C170.48 6.62352 171.338 5.37094 172.394 4.44799C173.516 3.52503 174.704 2.86578 176.024 2.47023C177.344 2.07468 178.73 1.81097 180.314 1.81097C182.822 1.81097 184.736 2.00875 186.056 2.47023C186.65 2.60208 186.914 2.9317 186.914 3.32726V7.085C186.914 7.6124 186.65 7.87611 186.188 7.87611H186.056C183.482 7.67833 181.568 7.54648 180.38 7.54648C177.212 7.54648 175.628 9.72201 175.628 14.0731V18.9516C175.628 23.3026 177.212 25.4782 180.38 25.4782C181.568 25.4782 183.482 25.3463 186.056 25.1485H186.188C186.65 25.1485 186.914 25.4122 186.914 25.9396V29.6315Z"
          :fill="foregroundFill"/>
    <path d="M210.212 6.95316C210.212 7.15093 210.146 7.34871 209.948 7.48056C209.75 7.61241 209.618 7.67834 209.42 7.67834H203.15V30.093C203.15 30.2907 203.084 30.4885 202.886 30.6204C202.688 30.7522 202.556 30.8841 202.358 30.8841H197.342C197.144 30.8841 196.946 30.8181 196.814 30.6204C196.682 30.4226 196.55 30.2907 196.55 30.093V7.67834H190.28C190.082 7.67834 189.884 7.61241 189.752 7.48056C189.62 7.34871 189.554 7.15093 189.554 6.95316V2.99764C189.554 2.79986 189.62 2.60209 189.752 2.47024C189.884 2.33839 190.082 2.27246 190.28 2.27246H209.42C209.618 2.27246 209.816 2.33839 209.948 2.47024C210.146 2.60209 210.212 2.79986 210.212 2.99764V6.95316Z"
          :fill="foregroundFill"/>
    <path d="M80.654 51.1231C80.654 51.1891 80.588 51.3209 80.522 51.3868C80.456 51.4528 80.39 51.5187 80.258 51.5187H78.542C78.476 51.5187 78.344 51.4528 78.278 51.3868C78.212 51.3209 78.146 51.255 78.146 51.1231V38.7951C78.146 38.7292 78.212 38.5973 78.278 38.5314C78.344 38.4655 78.41 38.4655 78.542 38.4655H80.258C80.324 38.4655 80.456 38.4655 80.522 38.5314C80.588 38.5973 80.654 38.6633 80.654 38.7951V51.1231Z"
          :fill="foregroundFill"/>
    <path d="M93.326 50.7276C93.326 50.9253 93.26 51.0572 93.128 51.189C92.996 51.3209 92.798 51.3868 92.6 51.3868H90.818C90.62 51.3868 90.488 51.3868 90.422 51.3868C90.356 51.3868 90.224 51.3209 90.092 51.189C89.96 51.0572 89.894 50.9253 89.762 50.7276L85.868 42.2232C85.802 42.1573 85.802 42.0914 85.736 42.0914C85.67 42.0914 85.67 42.1573 85.67 42.2232V50.9913C85.67 51.0572 85.67 51.189 85.604 51.255C85.538 51.3209 85.472 51.3868 85.34 51.3868H83.822C83.756 51.3868 83.624 51.3209 83.558 51.255C83.492 51.189 83.426 51.1231 83.426 50.9913V39.1247C83.426 38.9269 83.492 38.7292 83.624 38.5973C83.756 38.4655 83.954 38.3995 84.152 38.3995H86.066C86.264 38.3995 86.396 38.4655 86.528 38.5314C86.66 38.5973 86.726 38.7292 86.858 38.9929L90.95 47.9587C91.016 48.0246 91.016 48.0906 91.082 48.0906C91.148 48.0906 91.148 48.0246 91.148 47.9587V38.7951C91.148 38.5973 91.28 38.4655 91.478 38.4655H92.996C93.194 38.4655 93.326 38.5973 93.326 38.7951V50.7276Z"
          :fill="foregroundFill"/>
    <path d="M104.018 40.1795C104.018 40.3773 103.886 40.5092 103.688 40.5092H100.652V51.1231C100.652 51.1891 100.586 51.3209 100.52 51.3868C100.454 51.4528 100.388 51.5187 100.256 51.5187H98.54C98.474 51.5187 98.342 51.4528 98.276 51.3868C98.21 51.3209 98.144 51.255 98.144 51.1231V40.5092H95.108C94.844 40.5092 94.778 40.3773 94.778 40.1795V38.7951C94.778 38.7292 94.844 38.5973 94.91 38.5314C94.976 38.4655 95.042 38.4655 95.174 38.4655H103.754C103.82 38.4655 103.952 38.4655 104.018 38.5314C104.084 38.5973 104.15 38.6633 104.15 38.7951V40.1795H104.018Z"
          :fill="foregroundFill"/>
    <path d="M113.258 50.9913C113.258 51.1231 113.258 51.1891 113.192 51.255C113.126 51.3209 113.06 51.3209 112.928 51.3868C111.872 51.5187 110.75 51.6505 109.628 51.6505C109.034 51.6505 108.44 51.5846 108.044 51.5187C107.648 51.4528 107.186 51.255 106.79 50.9913C106.394 50.7276 106.13 50.398 105.932 49.8705C105.734 49.3431 105.668 48.8157 105.668 48.0906V41.9595C105.668 41.2343 105.734 40.641 105.932 40.1795C106.13 39.718 106.394 39.3225 106.79 39.0588C107.186 38.7951 107.582 38.6632 108.044 38.5314C108.506 38.3995 109.034 38.3995 109.628 38.3995C110.816 38.3995 111.872 38.4655 112.928 38.6632C113.06 38.6632 113.126 38.7292 113.192 38.7951C113.258 38.861 113.258 38.9269 113.258 39.0588V40.1795C113.258 40.2454 113.192 40.3773 113.126 40.4432C113.06 40.5091 112.994 40.5751 112.862 40.5751H109.298C108.77 40.5751 108.44 40.7069 108.242 40.9047C108.044 41.1025 107.912 41.498 107.912 42.0914V44.0032H112.664C112.73 44.0032 112.862 44.0691 112.928 44.135C112.994 44.201 113.06 44.2669 113.06 44.3987V45.6513C113.06 45.7173 112.994 45.8491 112.928 45.915C112.862 45.981 112.796 46.0469 112.664 46.0469H107.912V48.2883C107.912 48.8817 108.044 49.2772 108.242 49.475C108.44 49.6728 108.836 49.8046 109.298 49.8046H112.862C112.928 49.8046 113.06 49.8705 113.126 49.9365C113.192 50.0024 113.258 50.0683 113.258 50.2002V50.9913Z"
          :fill="foregroundFill"/>
    <path d="M125.27 51.255C125.27 51.3868 125.204 51.4528 125.072 51.4528H122.96C122.828 51.4528 122.762 51.3209 122.63 51.1231L120.122 46.4424H119.858C119.726 46.4424 119.528 46.4424 119.33 46.4424C119.132 46.4424 118.934 46.4424 118.736 46.3765C118.538 46.3765 118.34 46.3765 118.274 46.3765V51.1231C118.274 51.1891 118.274 51.3209 118.208 51.3868C118.142 51.4528 118.076 51.5187 117.944 51.5187H116.228C116.162 51.5187 116.03 51.4528 115.964 51.3868C115.898 51.3209 115.832 51.255 115.832 51.1231V39.0588C115.832 38.7951 116.03 38.5973 116.36 38.5314C117.35 38.3995 118.472 38.3336 119.792 38.3336C120.518 38.3336 121.178 38.3995 121.706 38.5314C122.3 38.6632 122.828 38.861 123.29 39.1906C123.752 39.5203 124.148 39.9158 124.412 40.4432C124.676 40.9706 124.808 41.564 124.808 42.2891V42.421C124.808 44.201 124.016 45.3876 122.432 45.981L125.204 51.0572C125.27 51.1231 125.27 51.1891 125.27 51.255ZM122.432 42.421V42.2891C122.432 41.6299 122.234 41.1684 121.772 40.8388C121.31 40.5751 120.65 40.3773 119.792 40.3773C119.198 40.3773 118.67 40.3773 118.208 40.4432V44.3987C118.736 44.4647 119.264 44.4647 119.792 44.4647C120.716 44.4647 121.376 44.3328 121.772 44.0032C122.234 43.6736 122.432 43.1462 122.432 42.421Z"
          :fill="foregroundFill"/>
    <path d="M137.282 50.7276C137.282 50.9253 137.216 51.0572 137.084 51.189C136.952 51.3209 136.754 51.3868 136.556 51.3868H134.84C134.642 51.3868 134.51 51.3868 134.444 51.3868C134.378 51.3868 134.246 51.3209 134.114 51.189C133.982 51.0572 133.916 50.9253 133.784 50.7276L129.89 42.2232C129.824 42.1573 129.824 42.0914 129.758 42.0914C129.692 42.0914 129.692 42.1573 129.692 42.2232V50.9913C129.692 51.0572 129.692 51.189 129.626 51.255C129.56 51.3209 129.494 51.3868 129.362 51.3868H127.844C127.778 51.3868 127.646 51.3209 127.58 51.255C127.514 51.189 127.448 51.1231 127.448 50.9913V39.1247C127.448 38.9269 127.514 38.7292 127.646 38.5973C127.778 38.4655 127.976 38.3995 128.174 38.3995H130.088C130.286 38.3995 130.418 38.4655 130.55 38.5314C130.682 38.5973 130.748 38.7292 130.88 38.9929L134.972 47.9587C135.038 48.0246 135.038 48.0906 135.104 48.0906C135.17 48.0906 135.17 48.0246 135.17 47.9587V38.7951C135.17 38.5973 135.302 38.4655 135.5 38.4655H137.018C137.216 38.4655 137.348 38.5973 137.348 38.7951V50.7276H137.282Z"
          :fill="foregroundFill"/>
    <path d="M149.756 51.1231C149.756 51.3209 149.624 51.4528 149.426 51.4528H147.578C147.38 51.4528 147.314 51.3209 147.248 51.1231L146.456 47.8269H142.43L141.638 51.1231C141.572 51.3209 141.506 51.4528 141.308 51.4528H139.46C139.262 51.4528 139.13 51.3209 139.13 51.1231V51.0572V50.9913L141.968 40.0477C142.298 38.861 143.09 38.2677 144.41 38.2677C145.73 38.2677 146.522 38.861 146.852 40.0477L149.69 50.9913C149.756 51.0572 149.756 51.0572 149.756 51.1231ZM145.994 45.8491L144.74 40.641C144.674 40.4432 144.608 40.3773 144.476 40.3773C144.344 40.3773 144.212 40.4432 144.212 40.641L142.958 45.8491H145.994Z"
          :fill="foregroundFill"/>
    <path d="M158.732 40.1795C158.732 40.3773 158.6 40.5092 158.402 40.5092H155.3V51.1231C155.3 51.1891 155.234 51.3209 155.168 51.3868C155.102 51.4528 155.036 51.5187 154.904 51.5187H153.188C153.122 51.5187 152.99 51.4528 152.924 51.3868C152.858 51.3209 152.792 51.255 152.792 51.1231V40.5092H149.756C149.492 40.5092 149.426 40.3773 149.426 40.1795V38.7951C149.426 38.7292 149.492 38.5973 149.558 38.5314C149.624 38.4655 149.69 38.4655 149.822 38.4655H158.402C158.468 38.4655 158.6 38.4655 158.666 38.5314C158.732 38.5973 158.798 38.6633 158.798 38.7951V40.1795H158.732Z"
          :fill="foregroundFill"/>
    <path d="M163.088 51.1231C163.088 51.1891 163.022 51.3209 162.956 51.3868C162.89 51.4528 162.824 51.5187 162.692 51.5187H160.976C160.91 51.5187 160.778 51.4528 160.712 51.3868C160.646 51.3209 160.58 51.255 160.58 51.1231V38.7951C160.58 38.7292 160.646 38.5973 160.712 38.5314C160.778 38.4655 160.844 38.4655 160.976 38.4655H162.692C162.758 38.4655 162.89 38.4655 162.956 38.5314C163.022 38.5973 163.088 38.6633 163.088 38.7951V51.1231Z"
          :fill="foregroundFill"/>
    <path d="M175.562 46.1128C175.562 47.9587 175.1 49.3431 174.242 50.2002C173.318 51.1231 172.13 51.5187 170.612 51.5187C169.094 51.5187 167.84 51.0572 166.982 50.2002C166.058 49.2772 165.662 47.9587 165.662 46.1128V43.7395C165.662 41.8936 166.124 40.5092 166.982 39.6521C167.906 38.7292 169.094 38.3336 170.612 38.3336C172.13 38.3336 173.384 38.7951 174.242 39.6521C175.166 40.5751 175.562 41.8936 175.562 43.7395V46.1128ZM173.054 46.1128V43.7395C173.054 42.6188 172.856 41.7617 172.46 41.2343C172.064 40.7069 171.404 40.4432 170.546 40.4432C169.688 40.4432 169.028 40.7069 168.632 41.2343C168.236 41.7617 168.038 42.6188 168.038 43.7395V46.1128C168.038 47.2335 168.236 48.0906 168.632 48.618C169.028 49.1454 169.688 49.4091 170.546 49.4091C171.404 49.4091 172.064 49.1454 172.46 48.618C172.856 48.0906 173.054 47.2995 173.054 46.1128Z"
          :fill="foregroundFill"/>
    <path d="M187.838 50.7276C187.838 50.9253 187.772 51.0572 187.64 51.189C187.508 51.3209 187.31 51.3868 187.112 51.3868H185.33C185.132 51.3868 185 51.3868 184.934 51.3868C184.868 51.3868 184.736 51.3209 184.604 51.189C184.472 51.0572 184.406 50.9253 184.274 50.7276L180.38 42.2232C180.314 42.1573 180.314 42.0914 180.248 42.0914C180.182 42.0914 180.182 42.1573 180.182 42.2232V50.9913C180.182 51.0572 180.182 51.189 180.116 51.255C180.05 51.3209 179.984 51.3868 179.852 51.3868H178.4C178.334 51.3868 178.202 51.3209 178.136 51.255C178.07 51.189 178.004 51.1231 178.004 50.9913V39.1247C178.004 38.9269 178.07 38.7292 178.202 38.5973C178.334 38.4655 178.532 38.3995 178.73 38.3995H180.644C180.842 38.3995 180.974 38.4655 181.106 38.5314C181.238 38.5973 181.304 38.7292 181.436 38.9929L185.528 47.9587C185.594 48.0246 185.594 48.0906 185.66 48.0906C185.726 48.0906 185.726 48.0246 185.726 47.9587V38.7951C185.726 38.5973 185.858 38.4655 186.056 38.4655H187.574C187.772 38.4655 187.904 38.5973 187.904 38.7951V50.7276H187.838Z"
          :fill="foregroundFill"/>
    <path d="M200.312 51.1231C200.312 51.3209 200.18 51.4528 199.982 51.4528H198.134C197.936 51.4528 197.87 51.3209 197.804 51.1231L197.012 47.8269H192.986L192.194 51.1231C192.128 51.3209 192.062 51.4528 191.864 51.4528H190.016C189.818 51.4528 189.686 51.3209 189.686 51.1231V51.0572V50.9913L192.524 40.0477C192.854 38.861 193.646 38.2677 194.966 38.2677C196.286 38.2677 197.078 38.861 197.408 40.0477L200.246 50.9913C200.312 51.0572 200.312 51.0572 200.312 51.1231ZM196.55 45.8491L195.296 40.641C195.23 40.4432 195.164 40.3773 195.032 40.3773C194.9 40.3773 194.768 40.4432 194.768 40.641L193.514 45.8491H196.55Z"
          :fill="foregroundFill"/>
    <path d="M209.222 50.9913C209.222 51.1232 209.222 51.1891 209.156 51.255C209.09 51.3209 209.024 51.3209 208.892 51.3869C208.166 51.5187 207.176 51.6506 205.922 51.6506C205.328 51.6506 204.734 51.5846 204.338 51.5187C203.876 51.4528 203.48 51.255 203.084 50.9913C202.688 50.7276 202.424 50.398 202.226 49.8706C202.028 49.3432 201.962 48.8158 201.962 48.0906V38.861C201.962 38.7951 202.028 38.6633 202.094 38.5973C202.16 38.5314 202.226 38.4655 202.358 38.4655H204.074C204.14 38.4655 204.272 38.5314 204.338 38.5973C204.404 38.6633 204.47 38.7292 204.47 38.861V48.0906C204.47 48.6839 204.602 49.0795 204.8 49.2772C204.998 49.475 205.328 49.6069 205.856 49.6069H209.09C209.156 49.6069 209.288 49.6728 209.354 49.7387C209.42 49.8046 209.486 49.8706 209.486 50.0024V50.9913H209.222Z"
          :fill="foregroundFill"/>
    <path d="M0.92601 3.98652C0.92601 3.98652 16.7 4.57984 16.7 12.359C16.7 17.3034 12.674 19.8745 12.674 19.8745C12.674 19.8745 26.336 18.6219 26.336 10.0516C26.336 1.48135 10.232 -2.07861 0.92601 3.98652Z"
          :fill="foregroundFill"/>
  </svg>
</template>

<script>
export default {
  name: 'ImpactLogo',
  props: {
    fill: {
      type: String,
      default: 'white',
    },
    backgroundFill: {
      type: String,
      default: 'rgba(0,0,0,0)',
    },
  },
  computed: {
    foregroundFill() {
      return this.fill || this.$vuetify.theme.themes.light.primary;
    },
  }
};
</script>
