<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="6" lg="3">
          <v-sheet>
            <v-row class="secondary px-4 pt-3 pb-6">
              <ImpactLogo @click.native="handleLogoClick" :role="$route.name !== 'login' && 'button'"/>
            </v-row>
            <v-row style="background-color: #fff">
              <router-view/>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script lang="js">
import ImpactLogo from '@/images/ImpactInternationalLogo.vue';

export default {
  name: 'Authorisation',
  components: { ImpactLogo },
  methods: {
    handleLogoClick() {
      if (this.$route.name !== 'login') this.$router.push({ name: 'login' });
    },
  },
};
</script>
